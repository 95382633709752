<template>
  <CContainer fluid>
    <CTabs variant="tabs" :active-tab="0" @update:activeTab="onActiveTab">
      <CTab title="Info">
        <CRow>
          <CCol>
            <CCard>
              <CCardHeader>
                <CRow class="justify-content-between px-3">
                  <b>Provider</b>
                  <div class="d-flex">
                    <CButton
                      @click="isShowEditProviderInfoModal = true"
                      size="sm"
                      color="primary"
                      class="mr-2 font-weight-bold"
                      >Edit</CButton
                    >
                    <CButton
                      @click="isEditSPCategoriesModal = true"
                      size="sm"
                      color="danger"
                      class="mr-2 font-weight-bold"
                      >Edit Categories</CButton
                    >
                    <CButton
                      @click="linkStripeAccountModal = true"
                      size="sm"
                      color="info"
                      class="mr-2 font-weight-bold"
                      :disabled="providerSetting.stripe_account_id != ''"
                      >Link Stripe Account</CButton
                    >
                    <CButton
                      @click="changeStatusModal = true"
                      size="sm"
                      color="warning"
                      class="mr-2 font-weight-bold"
                      >Change Status</CButton
                    >
                    <CButton
                      size="sm"
                      color="dark"
                      class="mr-2 font-weight-bold"
                      @click="onFetchNoteInternal"
                      >Internal Notes</CButton
                    >
                  </div>
                </CRow>
              </CCardHeader>
              <CCardBody>
                <CRow>
                  <dd class="col-sm-12 d-flex">
                    <div style="width: 100px" class="mr-2">
                      <expandable-image
                        :src="provider.profile_url || 'img/no-image.png'"
                        :close-on-background-click="true"
                        class="mb-2"
                        shape="rounded"
                      />
                    </div>
                  </dd>

                  <dt class="col-sm-2">ID</dt>
                  <dd class="col-sm-10">{{ provider.id }}</dd>

                  <dt class="col-sm-2">Alias</dt>
                  <dd class="col-sm-10">{{ provider.name }}</dd>

                  <dt class="col-sm-2">BusinessName</dt>
                  <dd class="col-sm-10">
                    {{ provider.business_name ? provider.business_name : '-' }}
                  </dd>

                  <dt class="col-sm-2">Address</dt>
                  <dd class="col-sm-10">
                    {{ provider.address ? provider.address : '-' }}
                  </dd>
                  <dt class="col-sm-2">UnitNumber</dt>
                  <dd class="col-sm-10">
                    {{ provider.unit_number ? provider.unit_number : '-' }}
                  </dd>
                  <dt class="col-sm-2">PostalCode</dt>
                  <dd class="col-sm-10">
                    {{ provider.postal_code ? provider.postal_code : '-' }}
                  </dd>

                  <dt class="col-sm-2">BusinessType</dt>
                  <dd class="col-sm-10">{{ provider.type }}</dd>

                  <dt class="col-sm-2">Categories</dt>
                  <dd class="col-sm-10">
                    <template v-for="category in provider.categories">
                      <CBadge
                        :color="`success`"
                        class="mr-1"
                        :key="category.id"
                      >
                        {{ category.name }}
                      </CBadge>
                    </template>
                  </dd>

                  <dt class="col-sm-2">Status</dt>
                  <dd class="col-sm-10">
                    <CBadge :color="getProviderBadge(provider.status)">
                      {{ provider.status }}
                    </CBadge>
                  </dd>

                  <dt class="col-sm-2">Description</dt>
                  <dd class="col-sm-10">{{ provider.description }}</dd>

                  <dt class="col-sm-2">Stripe Account ID</dt>
                  <dd class="col-sm-10">
                    <span
                      v-if="
                        providerSetting && providerSetting.stripe_account_id
                      "
                    >
                      <CLink
                        :href="
                          env.stripeURL +
                          '/connect/accounts/' +
                          providerSetting.stripe_account_id
                        "
                        target="_blank"
                      >
                        {{ providerSetting.stripe_account_id }}
                      </CLink>
                    </span>
                    <span v-else>-</span>
                  </dd>
                  <dt class="col-sm-2">Stripe Bank Account</dt>
                  <dd class="col-sm-10">
                    <span
                      v-if="providerSetting && providerSetting.bank_account_id"
                    >
                      <CLink
                        :href="
                          env.stripeURL +
                          '/connect/accounts/' +
                          providerSetting.stripe_account_id
                        "
                        target="_blank"
                      >
                        {{ providerSetting.bank_account_id }}
                      </CLink>
                    </span>
                    <span v-else>-</span>
                  </dd>

                  <dt class="col-sm-2">RegisteredID</dt>
                  <dd class="col-sm-10">
                    {{ provider.registered_id ? provider.registered_id : '-' }}
                  </dd>
                  <dt class="col-sm-2">GST</dt>
                  <dd class="col-sm-10">
                    {{
                      provider.type == 'company' && provider.gst
                        ? provider.gst
                        : '-'
                    }}
                  </dd>
                  <dt class="col-sm-2">Years of experience</dt>
                  <dd class="col-sm-10">
                    {{ provider.yoe ? provider.yoe : '-' }}
                  </dd>

                  <dt class="col-sm-2">Insurance expiry date</dt>
                  <dd class="col-sm-10">
                    <span
                      style="color: red"
                      v-if="
                        provider.insurance_expiry_date &&
                        moment(provider.insurance_expiry_date).diff(
                          moment(),
                          'days'
                        ) < 14 &&
                        moment(provider.insurance_expiry_date).diff(
                          moment(),
                          'days'
                        ) >= 0
                      "
                    >
                      {{ provider.insurance_expiry_date | moment(dateFormat) }}
                      (expiring)
                    </span>
                    <span
                      style="color: red"
                      v-else-if="
                        provider.insurance_expiry_date &&
                        moment(provider.insurance_expiry_date).diff(
                          moment(),
                          'days'
                        ) < 0
                      "
                    >
                      {{ provider.insurance_expiry_date | moment(dateFormat) }}
                      (expired)
                    </span>
                    <span v-else>
                      {{
                        (provider.insurance_expiry_date
                          ? provider.insurance_expiry_date
                          : '-') | moment(dateFormat)
                      }}
                    </span>
                  </dd>

                  <dt class="col-sm-2">Rating</dt>
                  <dd class="col-sm-10">
                    {{ provider.rate }}
                  </dd>
                  <dt class="col-sm-2">Num of Rating</dt>
                  <dd class="col-sm-10">
                    {{ provider.num_rating }}
                  </dd>
                  <dt class="col-sm-2">Num of Review</dt>
                  <dd class="col-sm-10">
                    {{ provider.review }}
                  </dd>
                  <dt class="col-sm-2">Num of Job Completed</dt>
                  <dd class="col-sm-10">
                    {{ provider.num_job_completed }}
                  </dd>
                  <dt class="col-sm-2">Num of requested Booking</dt>
                  <dd class="col-sm-10">
                    {{ provider.num_requested_booking }}
                  </dd>
                  <dt class="col-sm-2">Num of cancelled Booking</dt>
                  <dd class="col-sm-10">
                    {{ provider.num_cancelled_booking }}
                  </dd>
                  <dt class="col-sm-2">Num of expired Booking</dt>
                  <dd class="col-sm-10">
                    {{ provider.num_expired_booking }}
                  </dd>
                  <dt class="col-sm-2">Created date</dt>
                  <dd class="col-sm-10">
                    {{ provider.created_at | moment(datetimeFormat) }}
                  </dd>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <CCard>
              <CCardHeader>
                <b>Photos </b>
                <CInputFile
                  v-if="uploadPhotoReady"
                  ref="inputFilePhoto"
                  horizontal
                  multiple
                  accept=".jpg,.jpeg,.png"
                  class="mr-1 float-right"
                  @change="onUploadPhoto"
                />
                <!-- <CButton
                  @click="uploadDocumentModal = true"
                  size="sm"
                  color="info"
                  class="mr-1 float-right"
                  >Upload Document
                </CButton> -->
              </CCardHeader>
              <CCardBody>
                <CDataTable
                  border
                  striped
                  :items="provider.photos"
                  :fields="photoFields"
                >
                  <td slot="thumbnail" slot-scope="{ item }">
                    <expandable-image
                      :src="item.url || 'img/no-image.png'"
                      :close-on-background-click="true"
                    />
                  </td>
                  <td slot="created_at" slot-scope="{ item }">
                    {{ item.created_at | moment(datetimeFormat) }}
                  </td>
                  <td slot="action" slot-scope="{ item }">
                    <CButton
                      @click="onDeleteProviderPhoto(item)"
                      size="sm"
                      color="danger"
                      class="mr-2"
                      >Delete</CButton
                    >
                  </td>
                </CDataTable>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CTab>

      <CTab title="Owner">
        <CRow>
          <CCol>
            <CCard>
              <CCardHeader> <b>Owner Detail</b></CCardHeader>
              <CCardBody>
                <CRow>
                  <dt class="col-sm-2">UserID</dt>
                  <dd class="col-sm-10">
                    <CLink :href="'#/consumers/' + owner.id">
                      {{ owner.id }}
                    </CLink>
                  </dd>

                  <dt class="col-sm-2">Name</dt>
                  <dd class="col-sm-10">
                    {{ owner.name ? owner.name : '-' }}
                  </dd>

                  <dt class="col-sm-2">Email</dt>
                  <dd class="col-sm-10">{{ owner.email }}</dd>

                  <dt class="col-sm-2">PhoneNumber</dt>
                  <dd class="col-sm-10">{{ owner.phone_number }}</dd>

                  <dt class="col-sm-2">Status</dt>
                  <dd class="col-sm-10">
                    <CBadge :color="getUserBadge(owner.status)">
                      {{ owner.status }}
                    </CBadge>
                  </dd>

                  <dt class="col-sm-2">LastLogin</dt>
                  <dd class="col-sm-10">
                    {{ moment(owner.last_login).fromNow() }}
                  </dd>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CTab>

      <CTab title="Documents">
        <CRow>
          <CCol>
            <CCard>
              <CCardHeader>
                <b>Documents</b>
                <CButton
                  @click="uploadDocumentModal = true"
                  size="sm"
                  color="info"
                  class="mr-1 float-right"
                  >Upload Document</CButton
                >
              </CCardHeader>
              <CCardBody>
                <CDataTable
                  border
                  striped
                  :items="documents"
                  :fields="documentFields"
                >
                  <td slot="thumbnail" slot-scope="{ item }">
                    <span v-if="item.file_type == 'application/pdf'">
                      <CLink :href="item.url" target="_blank">
                        {{ 'pdf' }}
                      </CLink>
                    </span>
                    <span v-else>
                      <expandable-image
                        :src="item.url || 'img/no-image.png'"
                        :close-on-background-click="true"
                      />
                    </span>
                  </td>
                  <td slot="created_at" slot-scope="{ item }">
                    {{ item.created_at | moment(datetimeFormat) }}
                  </td>
                  <td slot="action" slot-scope="{ item }">
                    <CButton
                      @click="onDeleteProviderDocument(item)"
                      size="sm"
                      color="danger"
                      class="mr-2"
                      >Delete</CButton
                    >
                  </td>
                </CDataTable>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CTab>

      <CTab title="Services">
        <CRow>
          <CCol>
            <PostServiceComponent title="Services" :sp_id="$route.params.id" />
          </CCol>
        </CRow>
      </CTab>

      <CTab title="Invoices">
        <CRow>
          <CCol>
            <InvoiceListComponent
              title="Invoices"
              :items="invoices"
              :fields="invoicesFields"
            />
          </CCol>
        </CRow>
      </CTab>

      <CTab title="Bookings cancelled/no show by SP">
        <CRow>
          <CCol>
            <BookingHistoryComponent
              title="Bookings cancelled/no show by SP"
              :items="bookingHistories"
              :fields="bookingHistoryFields"
            />
          </CCol>
        </CRow>
      </CTab>
      <CTab title="SP Revenue">
        <CRow>
          <CCol>
            <RevenueListComponent
              :sp_id="$route.params.id"
              :csvFileName="`SP_${this.$route.params.id}_Finance.csv`"
              :fields="[
                { key: 'booking_id', label: 'BookingID' },
                { key: 'job_id', label: 'JobID' },
                { key: 'status', label: 'Status' },
                { key: 'type', label: 'Type' },
                { key: 'created_at', label: 'CreatedAt' },
                { key: 'revenue', label: 'Revenue' },
                { key: 'payout_amount', label: 'Payout' },
                { key: 'paidout_amount', label: 'Paid Out' },
              ]"
            />
          </CCol>
        </CRow>
      </CTab>

      <CTab title="SP Earning">
        <CRow>
          <CCol>
            <CCard>
              <CCardHeader> <b>SP Earning </b></CCardHeader>
              <CCardBody>
                <CDataTable
                  border
                  striped
                  :items="earningPayouts"
                  :fields="earningPayoutFields"
                >
                  <td slot="from_to" slot-scope="{ item }">
                    {{ item.day_from | moment(dateFormat) }} -
                    {{ item.day_to | moment(dateFormat) }}
                    <span>
                      {{ `${item.is_current_payout ? '- Current' : ''}` }}
                    </span>
                  </td>
                  <td slot="payout_day" slot-scope="{ item }">
                    <b>{{ item.payout_day | moment(dateFormat) }}</b>
                    <br />
                    {{ moment(item.payout_day).fromNow() }}
                  </td>
                  <td slot="revenue" slot-scope="{ item }">
                    <b>{{ item.revenue_amount | currency100 }}</b>
                  </td>
                  <td slot="payout" slot-scope="{ item }">
                    <b>{{ item.payout_amount | currency100 }}</b>
                  </td>
                  <td slot="tasks" slot-scope="{ item }">
                    {{ item.num_task }}
                  </td>

                  <template #show_details="{ item, index }">
                    <td class="py-2">
                      <CButton
                        color="primary"
                        variant="outline"
                        square
                        size="sm"
                        @click="toggleDetails(item, index)"
                      >
                        {{ Boolean(item._toggled) ? 'Hide' : 'Show' }}
                      </CButton>
                    </td>
                  </template>
                  <template #details="{ item }">
                    <CCollapse
                      :show="Boolean(item._toggled)"
                      :duration="collapseDuration"
                    >
                      <EarningTaskComponent :items="item.earning_tasks" />
                    </CCollapse>
                  </template>
                </CDataTable>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CTab>
    </CTabs>

    <CModal
      title="Change status of SP"
      :show.sync="changeStatusModal"
      size="lg"
    >
      <CForm>
        <CCol sm="4">
          <CSelect
            label="Status"
            :value.sync="statusSelected"
            :options="options"
          />
        </CCol>
        <CCol sm="8">
          <CTextarea
            label="Noted"
            :value.sync="noted"
            placeholder="Content..."
            rows="5"
          />
        </CCol>
      </CForm>
      <template #footer>
        <CButton @click="changeStatusModal = false" color="secondary"
          >Cancel</CButton
        >
        <CButton @click="onChangeStatus" color="primary">Save</CButton>
      </template>
    </CModal>

    <CModal title="Upload Doucment" :show.sync="uploadDocumentModal" size="lg">
      <CInputFile
        label="Multiple file input"
        horizontal
        multiple
        accept=".jpg,.jpeg,.png,.pdf"
        @change="onFileChange"
      />
      <hr />
      <div v-for="file in uploadFiles" :key="file.fileName">
        <CRow>
          <CCol sm="3">
            <CImg
              v-bind:src="file.url"
              width="100px"
              height="100px"
              class="mb-2 mr-2"
              shape="rounded"
            />
          </CCol>
          <CCol sm="8">
            <div>File: {{ file.fileName }}</div>
            <CInput
              label="Name"
              v-model="file.name"
              :horizontal="{ label: 'col-sm-2', input: 'col-sm-6' }"
              placeholder="Name of document"
            />
          </CCol>
        </CRow>
      </div>
      <template #footer>
        <CButton @click="onCancelUpload" color="secondary">Cancel</CButton>
        <CButton @click="onUploadDocument" color="primary">Upload</CButton>
      </template>
    </CModal>

    <CModal
      title="Link Stripe Account Connect"
      :show.sync="linkStripeAccountModal"
      size="lg"
    >
      <CForm>
        <CCol sm="8">
          <div class="h5">Account Connect must be:</div>
          <div style="color: red">
            - Account type is custom<br />
            - Set pay out schedule to manual<br />
            <br />
          </div>
          <CInput
            horizontal
            :value.sync="stripeAccountId"
            placeholder="Stripe Account ID..."
          />
        </CCol>
      </CForm>
      <template #footer>
        <CButton @click="linkStripeAccountModal = false" color="secondary"
          >Cancel</CButton
        >
        <CButton @click="onLinkStripeAccount" color="primary">Link</CButton>
      </template>
    </CModal>

    <EditProviderInfoModal
      :showModal="isShowEditProviderInfoModal"
      :providerData="provider"
      @close-modal="isShowEditProviderInfoModal = false"
      @save="onUpdateProvider"
    />

    <ViewNoteInternalComponent
      title="Notes"
      :isShow="showNoteInternalList"
      :noteData="noteData"
      @close="showNoteInternalList = false"
    />

    <EditProviderCategoryModal
      :showModal="isEditSPCategoriesModal"
      :categories="provider.categories || []"
      :fullCategories="categories"
      @close="isEditSPCategoriesModal = false"
      @save="onEditProviderCategory"
    />

    <vue-confirm-dialog></vue-confirm-dialog>
  </CContainer>
</template>

<script>
import ProviderProxy from '@/proxies/provider.proxy';
import UploadProxy from '@/proxies/upload.proxy';
import { mapGetters } from 'vuex';
import {
  ACTION_FETCH_PROVIDER,
  ACTION_FETCH_PROVIDER_SETTING,
  ACTION_FETCH_DOCUMENTS_OF_PROVIDER,
  ACTION_FETCH_SERVICES_OF_PROVIDER,
  ACTION_FETCH_BOOKING_HISTORY,
  ACTION_FETCH_CATEGORIES,
  ACTION_FETCH_INVOICES,
  ACTION_FETCH_PROVIDER_EARNING_PAYOUT,
} from '@/store/actions.type';
import { MUTATION_SET_PROVIDER } from '@/store/mutations.type';

import { ProviderBadge, UserBadge } from '@/utils/utils';
import env from '@/constants/env';
import { Common } from '@/constants';
import EditProviderInfoModal from '@/components/modals/EditProviderInfoModal';
import BookingHistoryComponent from '@/components/BookingHistoryComponent';
import AdminProxy from '@/proxies/admin.proxy';
import ViewNoteInternalComponent from '@/components/ViewNoteInternalComponent';
import EditProviderCategoryModal from '@/components/modals/EditProviderCategoryModal';
import InvoiceListComponent from '@/components/InvoiceListComponent';
import RevenueListComponent from '@/components/RevenueListComponent';
import EarningTaskComponent from '@/components/EarningTaskComponent';
import PostServiceComponent from '@/components/PostServiceComponent';
// import ListOwedHistoryComponent from '@/components/ListOwedHistoryComponent';

export default {
  name: 'SP',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.usersOpened = from.fullPath.includes('providers');
    });
  },
  components: {
    EditProviderInfoModal,
    BookingHistoryComponent,
    ViewNoteInternalComponent,
    EditProviderCategoryModal,
    InvoiceListComponent,
    RevenueListComponent,
    EarningTaskComponent,
    PostServiceComponent,
    // ListOwedHistoryComponent,
  },
  data() {
    return {
      env,
      datetimeFormat: Common.datetimeFormat,
      dateFormat: Common.dateFormat,
      id: undefined,
      changeStatusModal: false,
      uploadDocumentModal: false,
      linkStripeAccountModal: false,
      files: [],
      uploadFiles: [],
      statusSelected: 'pending',
      noted: '',
      stripeAccountId: '',
      options: [
        { value: 'active', label: 'Active' },
        { value: 'pending', label: 'Pending' },
        { value: 'approved', label: 'Approved' },
        { value: 'rejected', label: 'Rejected' },
        { value: 'banned', label: 'Banned' },
        { value: 'suspended', label: 'Suspended' },
      ],
      photoFields: [
        {
          key: 'thumbnail',
          label: 'Thumbnail',
          _style: 'width: 60px',
        },
        {
          key: 'created_at',
          label: 'Created Date',
        },
        {
          key: 'action',
          label: 'Action',
        },
      ],
      documentFields: [
        {
          key: 'thumbnail',
          label: 'Thumbnail',
          _style: 'width: 60px',
        },
        {
          key: 'description',
          label: 'Name of Document',
        },
        {
          key: 'created_at',
          label: 'Created Date',
        },
        {
          key: 'action',
          label: 'Action',
        },
      ],
      bookingHistoryFields: [
        {
          key: 'booking_id',
          label: 'ID',
        },
        {
          key: 'booking_no',
          label: 'BookingID',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'created_at',
          label: 'CreatedDate',
        },
      ],
      serviceFields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'category',
          label: 'Category',
        },
        {
          key: 'subcategory',
          label: 'Subcategory',
        },
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'unit_type',
          label: 'UnitType',
        },
        {
          key: 'num_of_units',
          label: 'Units',
        },
        {
          key: 'base_price',
          label: 'Price Listed',
        },
        {
          key: 'price',
          label: 'Price Shown',
        },
        {
          key: 'updated_at',
          label: 'UpdatedDate',
        },
        {
          key: 'action',
          label: 'Action',
        },
      ],
      earningPayoutFields: [
        {
          key: 'from_to',
          label: 'From To',
        },
        {
          key: 'payout_day',
          label: 'Paid on',
        },
        {
          key: 'revenue',
          label: 'Revenue',
        },
        {
          key: 'payout',
          label: 'Payout',
        },
        {
          key: 'tasks',
          label: 'Tasks',
        },
        {
          key: 'show_details',
          label: '',
          _style: 'width:1%',
          sorter: false,
          filter: false,
        },
      ],
      isShowEditProviderInfoModal: false,
      showNoteInternalList: false,
      noteData: {
        notes: [],
        id: undefined,
        type: 'provider',
      },
      isEditSPCategoriesModal: false,
      invoicesFields: [
        {
          key: 'invoice_no',
          label: 'InvoiceNo',
        },
        {
          key: 'booking_no',
          label: 'BookingNo',
        },
        {
          key: 'issued_by',
          label: 'IssuedBy',
        },
        {
          key: 'invoice_type',
          label: 'Type',
        },
        {
          key: 'pdf_file',
          label: 'PDF',
        },
        {
          key: 'created_at',
          label: 'CreatedDate',
        },
      ],
      uploadPhotoReady: true,
      collapseDuration: 0,
    };
  },
  computed: {
    ...mapGetters({
      provider: 'provider',
      providerSetting: 'providerSetting',
      documents: 'documentsOfProvider',
      services: 'servicesOfProvider',
      bookingHistories: 'histories',
      categories: 'categories',
      invoices: 'invoices',
      earnings: 'earningPayouts',
    }),
    owner: function () {
      return this.provider.owner || {};
    },
    earningPayouts: function () {
      return this.earnings.map((x) => {
        return { ...x };
      });
    },
  },
  async mounted() {
    this.id = this.$route.params.id;
    this.noteData.id = this.id;

    await this.$store.dispatch(ACTION_FETCH_CATEGORIES, {});
    await this.$store.dispatch(ACTION_FETCH_PROVIDER, this.id);

    this.$store.dispatch(ACTION_FETCH_PROVIDER_SETTING, this.id);
    this.$store.dispatch(ACTION_FETCH_DOCUMENTS_OF_PROVIDER, this.id);
    this.$store.dispatch(ACTION_FETCH_SERVICES_OF_PROVIDER, this.id);
    this.$store.dispatch(ACTION_FETCH_PROVIDER_EARNING_PAYOUT, this.id);

    this.$store.dispatch(ACTION_FETCH_BOOKING_HISTORY, {
      action: 'provider-cancelled',
      provider_id: this.id,
    });

    this.$store.dispatch(ACTION_FETCH_INVOICES, { provider_id: this.id });
  },

  methods: {
    getProviderBadge(status) {
      return ProviderBadge(status);
    },
    getUserBadge(status) {
      return UserBadge(status);
    },
    goBack() {
      this.usersOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: '/providers' });
    },
    onFileChange(files) {
      this.files = [...this.files, ...files];

      let temps = [];
      this.files.forEach((f) => {
        temps.push({
          url: URL.createObjectURL(f),
          fileName: f.name,
          size: f.size,
          type: f.type,
        });
      });
      this.uploadFiles = temps;
    },
    onUploadDocument() {
      if (this.files.length <= 0) {
        return;
      }

      UploadProxy.uploadFile(this.files)
        .then((urls) => {
          let documents = [];
          this.uploadFiles.forEach((t, index) => {
            documents.push({
              url: urls[index],
              description: t.name,
              file_type: t.type,
            });
          });
          return ProviderProxy.addDocument(this.id, {
            upload_documents: documents,
          });
        })
        .then(() => {
          this.uploadDocumentModal = false;
          this.$store.dispatch(ACTION_FETCH_DOCUMENTS_OF_PROVIDER, this.id);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    clearUploadPhoto() {
      this.uploadPhotoReady = false;
      this.$nextTick(() => {
        this.uploadPhotoReady = true;
      });
    },
    onUploadPhoto(files) {
      const uploadPhotos = [];

      if (files?.length <= 0) {
        return;
      }

      for (let i = 0; i < files?.length; i++) {
        const f = files[i];
        uploadPhotos.push({
          url: URL.createObjectURL(f),
          fileName: f.name,
          size: f.size,
          type: f.type,
        });
      }

      UploadProxy.uploadFile(files)
        .then((urls) => {
          const photos = [];
          uploadPhotos.forEach((t, index) => {
            photos.push({
              url: urls[index],
              description: t.name,
              file_type: t.type,
            });
          });
          return ProviderProxy.addPhoto(this.id, {
            upload_photos: photos,
          });
        })
        .then(() => {
          this.$store.dispatch(ACTION_FETCH_PROVIDER, this.id);
          this.clearUploadPhoto();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onCancelUpload() {
      this.files = [];
      this.uploadFiles = [];

      this.uploadDocumentModal = false;
    },
    async onChangeStatus() {
      const data = {
        status: this.statusSelected,
        note: this.noted,
      };

      await ProviderProxy.changeStatus(this.id, data);

      await this.$store.dispatch(ACTION_FETCH_PROVIDER, this.id);

      this.changeStatusModal = false;
    },
    onLinkStripeAccount() {
      if (this.stripeAccountId == '') return;
      const data = { stripe_account_id: this.stripeAccountId };

      ProviderProxy.linkStripeAccount(this.id, data).then(() => {
        this.linkStripeAccountModal = false;
        this.$store.dispatch(ACTION_FETCH_PROVIDER_SETTING, this.id);
        this.$toast.success(`Link Stripe account is successfully`, {
          duration: 5000,
        });
      });
    },
    onUpdateProvider(providerData) {
      ProviderProxy.updateProvider(this.id, providerData).then(() => {
        this.isShowEditProviderInfoModal = false;
        this.$toast.success(`Update SP is successfully`, {
          duration: 5000,
        });

        this.$store.commit(MUTATION_SET_PROVIDER, {
          ...this.provider,
          ...providerData,
        });
      });
    },
    onFetchNoteInternal() {
      let query = { type: this.noteData.type, id: parseInt(this.id) };
      AdminProxy.fetchNoteInternal(query).then(({ data }) => {
        this.showNoteInternalList = true;
        this.noteData.notes = data;
      });
    },
    onEditProviderCategory(categoryIds) {
      ProviderProxy.changeCategories(this.id, {
        category_ids: categoryIds,
      }).then(() => {
        this.isEditSPCategoriesModal = false;
        this.$store.dispatch(ACTION_FETCH_PROVIDER, this.id);
        this.$toast.success(`Update Categories of SP is successfully`, {
          duration: 3000,
        });
      });
    },
    onActiveTab() {},
    async onDeleteProviderDocument(document) {
      try {
        this.$confirm({
          message: `Delete document. Are you sure?`,
          button: {
            no: 'No',
            yes: 'Yes',
          },
          callback: async (confirm) => {
            if (confirm) {
              await ProviderProxy.deleteDocument(this.id, document.id);
              this.$store.dispatch(ACTION_FETCH_DOCUMENTS_OF_PROVIDER, this.id);
            }
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    async onDeleteProviderPhoto(photo) {
      try {
        this.$confirm({
          message: `Delete photo. Are you sure?`,
          button: {
            no: 'No',
            yes: 'Yes',
          },
          callback: async (confirm) => {
            if (confirm) {
              await ProviderProxy.deletePhoto(this.id, photo.id);
              this.$store.dispatch(ACTION_FETCH_PROVIDER, this.id);
            }
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    toggleDetails(item, index) {
      this.$set(this.earningPayouts[index], '_toggled', !item._toggled);
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
    },
  },
};
</script>
